let FCMToken = "";
let db = "";

function receivedMessage(message) {
    const da = new Date(Date.now());
    var minutes = da.getMinutes().toString();
    if (minutes.length == 1) {
        minutes = `0${minutes}`
    }
    const time = da.getHours() + ":" + minutes;
    
    $('#message-container').html(
        `${$('#message-container').html()}
        <div class="chat-message from">
            <img src="assets/img/photos/AlanMaynard.jpg" alt="" data-demo-src="assets/img/avatars/alan.jpg">
            <div class="bubble-wrapper">
                <div name="timestamp" class="timestamp">${time}</div>
                <div class="chat-bubble">
                    ${message.data.text}
                </div>
            </div>
        </div>`);
    
    $('#message-container').scrollTop($('#message-container')[0].scrollHeight);
}

function sendMessage() {
    // Check that the user entered a message and is signed in.
    if ($("textarea#message").val()) {
        getNotificationPermission().then((p) => {
            if (p) {
                getAuth().then((v) => {
                    if (v) {
                        getToken().then((t) => {
                            const da = new Date(Date.now());
                            let minutes = da.getMinutes().toString();
                            if(minutes.length == 1) {
                                minutes = `0${minutes}`
                            }
                            const time = da.getHours() + ":" + minutes;
            
                            saveMessage($("textarea#message").val(), da.getUTCDate() + " " + (da.getUTCMonth() + 1) + " " + da.getUTCFullYear() + " " + time).then(function() {
                                $('#message-container').html(
                                    `${$('#message-container').html()}
                                    <div class="chat-message to">
                                        <div class="bubble-wrapper">
                                            <div class="timestamp">${time}</div>
                                            <div class="chat-bubble">
                                                ${$("textarea#message").val()}
                                            </div>
                                        </div>
                                        <img src="https://via.placeholder.com/250x250" alt=""
                                            data-demo-src="assets/img/avatars/helen.jpg">
                                    </div>`);
                                
                                $('#message').val('');
                                $('#message-container').scrollTop($('#message-container')[0].scrollHeight);
                            });
            
                            $.ajax({
                                type: "POST",
                                headers: {
                                    "api-key": "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",
                                    "content-type": "application/json",
                                    "accept": "application/json"
                                },
                                url: "https://api.sendinblue.com/v3/smtp/email",
                                data: `{  
                                    "sender": {  
                                        "name":"Clinic Framework Web",
                                        "email":"no-reply@clinicframework.com"
                                    },
                                    "to": [  
                                        {  
                                            "email":"sales@clinicframework.com",
                                            "name":"Clinic Framework Sales"
                                        }
                                    ],
                                        "subject":"Chat Message",
                                        "htmlContent":"<html><body>${$("textarea#message").val()} from ${FCMToken}</body></html>"
                                    }`,
                                success: function (result) {},
                                dataType: "json"
                            });
                        });
                    }
                });
            }
        });   
    }
}

async function saveMessage(messageText, timestamp) {
    // Add a new message entry to the Firebase database.
    try {
        return db.collection("Chats").add({
            name: FCMToken,
            text: messageText,
            timestamp: timestamp
        });
    }
    catch(error) {
        console.error('Error writing new message to Firebase Database', error);
    }
}

function initFirebase() {
    const firebaseConfig = {
        apiKey: "AIzaSyB0oW949Ppk1KQCnRrP5YPWkMnEU4bc0qU",
        authDomain: "clinicframework.com",
        projectId: "clinicframework",
        storageBucket: "clinicframework.appspot.com",
        messagingSenderId: "1044455505460",
        appId: "1:1044455505460:web:53ffab8be8b33ec7afd461",
        measurementId: "G-VZ80NVXPN9"
    };
    
    // Return the default app
    firebase.initializeApp(firebaseConfig);
    return firebase.firestore();
}

function getToken() {
    const messaging = firebase.messaging();
    return messaging.getToken({vapidKey: "BAUfA82CDBTfnn7m5fgp7jl_zIrW9M8uP8oltxBeHS8lsUVD5CP03UvJ_2Hci8V_7G-8f5k-VM_B2wgrgXln8NE"}).then((token) => {
        if (token) {
            FCMToken = token;
            messaging.onMessage((message) => {
                receivedMessage(message);
            });
            return true;
        }
        return false;
    });
}

function getAuth() {
    return firebase.auth().signInAnonymously().then((userCredential) => {
        return true;
    });
}

function getNotificationPermission() {
    return Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            return true;
        }
        else {
            iziToast.show({
                timeout: 10000,
                class: "danger-toast",
                icon: "sl sl-icon-lock",
                title: "Notification Permission,",
                message: "Please allow the notification permissions for this website to continue chatting.",
                titleColor: "#fff",
                messageColor: "#fff",
                iconColor: "#fff",
                backgroundColor: "#eda514",
                progressBarColor: "#444F60",
                position: "topRight",
                transitionIn: "fadeInDown",
                close: true,
                zindex: 99999,
            });
            return false;
        }
    })
}

$(document).ready(function() {
    db = initFirebase();
    const channel = new BroadcastChannel('chat-message');
    channel.onmessage = (message) => receivedMessage(message.data); 
});
