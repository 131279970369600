/* ==========================================================================
Commerce
========================================================================== */
"use strict";

function signUpSuccess() {
    iziToast.show({
        title: "Success,",
        message: "Your message has been sent!",
        position: "topLeft",
        titleColor: "#fff",
        messageColor: "#fff",
        iconColor: "#fff",
        backgroundColor: "#8c8cf9",
    });
}

function signUpError() {
    iziToast.show({
        class: "warning-toast",
        icon: "sl sl-icon-lock",
        title: "Failed,",
        message: "Failed to send the message!",
        titleColor: "#fff",
        messageColor: "#fff",
        iconColor: "#fff",
        backgroundColor: "#eda514",
        progressBarColor: "#444F60",
        position: "topRight",
        transitionIn: "fadeInDown",
        close: false,
        zindex: 99999,
    });
}

function signUpWithEmailAddress() {
    db.collection("SignUps").add({
        Name: "",
        Company: "",
        Email: $('#signUpWithEmail').val()
    }).then((docRef) => {
        // Show Success Popup
        signUpSuccess();
    }).catch((error) => {
        // Show PopUp Error
        signUpError();
    });
}

function signUpWithCompany() {
    db.collection("SignUps").add({
        Name: $('#signUpName').val(),
        Company: $('#signUpCompany').val(),
        Email: $('#signUpEmail').val()
    }).then((docRef) => {
        // Show Success Popup
        signUpSuccess();
    }).catch((error) => {
        // Show PopUp Error
        signUpError();
    });
}

$(document).ready(function () {
    const date = new Date(Date.now());
    let minutes = date.getMinutes().toString();
    if (minutes.length == 1) {
        minutes = `0${minutes}`;
    }
    const time = date.getHours() + ":" + minutes;
    $('#timestamp').html(time);
})
